<template>
  <div>
  <div class="d-flex justify-content-between align-items-center">
      <h4 style="font-weight: 500">Utilisateurs</h4> 
      <mdb-btn
              class="float-right"
              tag="a"
              color="primary"
              icon="plus"
              size="sm"
              small
              v-if="$gate.hasPermission(['create::user'])"
              @click="addOrEdit(null)"
            >
            </mdb-btn>
    </div>
    <hr class="" />
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 mx-auto">
        <mdb-card cascade>
          <mdb-view v-if="false"
            class="pl-2 pt-1 pb-1 d-flex justify-content-between card-header-reversed align-items-center"
            cascade
          >
            <div
              class="title-card-tec text-primary card-header-title color-primary-reversed"
              style="font-weight: 400"
            >
              Utilisateurs
            </div>
            <mdb-btn
              class="float-right"
              tag="a"
              color="primary"
              icon="plus"
              size="sm"
              small
              @click="addOrEdit(null)"
            >
            </mdb-btn>
          </mdb-view>

          <mdb-card-body
            :class="'card-reversed'"
            cascade
            v-loading="loading"
            class="card-reversed"
          >
            <mdb-row>
               <mdb-col md="12">
                <small>
                        <span
                        class="badge rounded-pill bg-success ml-n0"
                        style="height: 10px; width: 10px; display: inline-block"
                        ></span> Utilisateurs activés
                        <span
                        class="badge rounded-pill bg-danger ml-3"
                        style="height: 10px; width: 10px; display: inline-block"
                        ></span> Utilisateurs desactivés
                        <span
                        class="badge rounded-pill bg-secondary ml-3"
                        style="height: 10px; width: 10px; display: inline-block"
                        ></span> Utilisateurs archivés
                    </small>
              </mdb-col>
              <mdb-col sm="12" md="4" class="">
                <mdb-select
                  flipOnScroll
                  outline
                  @getValue="selected"
                  label="Nombre par page"
                  v-model="dataPagination.pagination.perPageOptions"
                />
              </mdb-col>
              <mdb-col sm="12" md="4" class=""> </mdb-col>
              <mdb-col sm="12" md="4" class="">
                <mdb-input
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  icon="search"
                  outline
                  type="text"
                  v-model="dataPagination.searchQuery"
                  placeholder="Rechercher"
                  aria-label="Search"
                />
              </mdb-col>
            </mdb-row>

            <mdb-tbl responsiveMd >
              <mdb-tbl-head>
                <tr class="color-normal-reversed">
                  <th v-for="column in data.columns" :key="column.name">
                    {{ column.label }}
                  </th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body class="color-normal-reversed">
                <tr class="tec-table-row" v-for="row in queriedData" :key="row.name">
                  <th class="tec-table-cell" scope="row">
                    <span v-if="row.is_active === 1 & row.is_archived === 0"
                    class="badge rounded-pill bg-success"
                    style="height: 10px; width: 10px; display: inline-block"
                    ></span>
                    <span
                    v-else-if="row.is_active === 0 & row.is_archived === 0"
                    class="badge rounded-pill bg-danger"
                    style="height: 10px; width: 10px; display: inline-block"
                    ></span>
                    <span
                    v-else
                    class="badge rounded-pill bg-secondary"
                    style="height: 10px; width: 10px; display: inline-block"
                    ></span>
                    {{ row.firstname }} {{ row.lastname }} <small>(profile {{row.role}})</small> <small v-if="row.is_admin_principal === 1">(root)</small>
                  </th>
                  <td class="tec-table-cell">{{ row.email }}</td>
                  <td class="tec-table-cell">{{ row.phone }}</td>
                  <td class="tec-table-cell">{{ row.site.name }}</td>
                  <!-- <td class="tec-table-cell" >
                                        <span v-if="row.is_archived === 1" class="badge badge-info"> archivé </span>
                                        <span v-if="row.is_archived === 0" class="badge badge-secondary"> non archivé </span>
                                    </td> -->
                  <td class="d-flex justify-content-end">
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        style="cursor: pointer; font-weight: 500"
                      >
                        Actions<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                        <el-dropdown-item
                          @click.native="details(row)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-notebook-2"
                          v-if="$gate.hasPermission(['view::user'])"
                          >Détails</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="addOrEdit(row)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-edit"
                          v-if="$gate.hasPermission(['edit::user']) && row.is_admin_principal === false"
                          >Modifier</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="deleteUser(row.id)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-delete"
                          v-if="$gate.hasPermission(['delete::user']) && row.is_admin_principal === false"
                          >Supprimer</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown> 
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
            <div v-if="empty">
              <div
                class="d-flex justify-content-around align-items-center color-normal-reversed"
                style="padding: 50px"
              >
                <div>
                  <h5>Commencez dès maintenant à ajouter vos premiers utilisateurs !</h5>
                  <p>
                    Pour ajouter un nouveau utilisateur, cliquez sur le bouton
                    <mdb-btn
                      class="z-depth-0"
                      style="cursor: inherit"
                      tag="a"
                      color="primary"
                      icon="plus"
                      size="sm"
                      small
                    ></mdb-btn>
                    en haut à votre droite.
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <tec-pagination
                v-model="dataPagination.pagination.currentPage"
                :per-page="parseFloat(dataPagination.pagination.perPage)"
                :total="dataPagination.pagination.total"
              ></tec-pagination>
            </div>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbView,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
} from "mdbvue";
import TecPagination from "@/tba/components/Pagination";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    TecPagination,
  },
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataPagination: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    queriedData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    addOrEdit(data = null) {
      this.$emit("add_or_edit", data);
    },

    details(row) {
      this.$emit("details_user", row);
    },

    getIsActiveValue() {
      //
    },

    loadUsers() {
      this.$emit("load_users");
    },

    selected(e) {
      this.$emit("per_page_selected", e);
    },

    deleteUser(id) {
      this.$emit("delete_user", id);
    },

    sendUserIdentifier(id) {
      this.$emit("send_user_identifier", id);
    },
  },
};
</script>
